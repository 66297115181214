import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Seo from "../components/seo"
import * as projectStyles from "../components/project.module.css"
import { Link } from "gatsby"
import Carousel from "react-bootstrap/Carousel"


const AWMarkePage = () => (
  <div>
    <Seo title="Marke für Altergott Webdesign GbR - Altergott Studios" lang="de" description="Ein neues Corporate Design wurde für die Webagentur Altergott Webdesign GbR erstellt, welches die Markenwerte von Minimalismus, Luxus und Innovation wiederspiegelt." />

    <Layout>
      <Header />
      <div>
        <div className={projectStyles.carouselWrapper}>
          <Carousel className={projectStyles.carousel}>
            <Carousel.Item>
              <div className="slide one"></div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide two"></div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide three"></div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide four"></div>
            </Carousel.Item>
          </Carousel>
        </div>

        <div className={projectStyles.projectGrid}>
          <div>
            <h3 className={projectStyles.uppercase}>Kunde</h3>

            <p>Altergott Webdesign GbR (Mitgründer)</p>
            <Link
              to="https://www.altergottwebdesign.com"
              className={projectStyles.projectLink}
            >
              altergottwebdesign.com
            </Link>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Projektbeschreibung</h3>

            <p>Leistungen: Erstellung eines Corporate Design</p>
            <p>
              Ein neues Corporate Design wurde für die Webagentur Altergott
              Webdesign GbR, wo ich ein Mitgründer war, erstellt, welches die
              Markenwerte von Minimalismus, Luxus und Innovation wiederspiegelt.
            </p>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Auswirkung</h3>

            <p>
              Das neue Corporate Design war wichtig, weil es einen guten und
              anhaltenden Eindruck auf neue Kunden und der Öffentlichkeit
              gemacht hat.
            </p>
            <p>
              Des Weiteren hat das neue Corporate Design die Markenwerte und
              Markenidentität der Agentur dem Kunden indirekt vermittelt.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  </div>
)

export default AWMarkePage
